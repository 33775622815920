import _rng from "./lib/rng";
import _bytesToUuid from "./lib/bytesToUuid";
var exports = {};
var rng = _rng;
var bytesToUuid = _bytesToUuid;

function v4(options, buf, offset) {
  var i = buf && offset || 0;

  if (typeof options == "string") {
    buf = options === "binary" ? new Array(16) : null;
    options = null;
  }

  options = options || {};
  var rnds = options.random || (options.rng || rng)(); // Per 4.4, set bits for version and `clock_seq_hi_and_reserved`

  rnds[6] = rnds[6] & 15 | 64;
  rnds[8] = rnds[8] & 63 | 128; // Copy bytes to buffer, if provided

  if (buf) {
    for (var ii = 0; ii < 16; ++ii) {
      buf[i + ii] = rnds[ii];
    }
  }

  return buf || bytesToUuid(rnds);
}

exports = v4;
export default exports;